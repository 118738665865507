section{
    width: 100%;
    display: flex;
    background-color: #E9E9E9;
    font-family: 'Raleway';
}
.add-employee{
    width: 100%;
    margin: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}
.section-name{
    font-size: 40px;
    font-weight: 900;
}
.section-button{
    width: 150px;
    height: 40px;
    border-radius: 25px;
    color: #fff;
    background-color: #4EC9AE;
    border: 1px solid #4EC9AE
}
.contents{
    width: 100%;
    margin:35px 40px 0 5px;
}
.content-menu-wrapper{
    width: 100%;
    margin:20px 40px 0 23px;
}

.content-menu{
    width: 95%;
    display: grid;
    grid-template-columns: 10% 30% 20% 20% 20% ;
    font-weight: bold;
    font-size: 12px;
}
.box{
    height: 20px;
    width: 20px;
    border-radius: 7px;
    background-color: #fff;
    border: 1px solid grey;
    margin: 0 auto
}
.employee-tag{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
}
.main-content{
    width: 100%;
    margin:20px 40px 0 23px;
} 
.content{
    width: 95%;
    display: grid;
    grid-template-columns: 5% 35% 20% 20% 20%;
    height: 70px;
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    align-items: center
}
.content:hover {
    transform: scale(1.03);
}
.box-content{
    margin-left: 24px
}
.user-profile{
    display: flex;
    padding-right: 40px
}
.user-profile-picture{
    margin:0 20px 0 30px
}
.user-profile-detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}
.profile-picture{
    height: 50px;
    width:50px;
    border-radius: 100%
}
.user-name{
    display: block;
    font-size: 15px;
    font-weight: 700
}
.user-title{
    font-size: 12px;
}
.user-salary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}
.salary{
    display: block;
    font-size: 15px;
    font-weight: 700
}
.salary-position{
    font-size: 12px;
}
.user-status{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}
.status{
    display: block;
    font-size: 15px;
}
.months{
    font-size: 12px;
}
.user-edit{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;

}

.edit{
    padding: 10px 0 10px 0
}
.edit-seperator{
    height: 40px;
    width: 2px;
    background-color: #E9E9E9;
    margin: 0 5px 0 5px;
}
.delete{
    padding: 10px 0 10px 0
}
svg{
    height: 20px;
    margin-left: 5px;
}
svg:hover{
    fill: red
}