.logo-red{
    width: 50px;
    height: 50px;
    background-color: #F6868D;
    border-radius: 100%;
}
.logo-white{
    margin: 0 auto;
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 100%;
    position: relative;
    top: 12.5px
}