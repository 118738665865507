.wrapper{
    width: 35%;
    margin: 40px 0 0 40px;
    padding-left: 50px;
    padding-top: 30px;
    margin-top: 30px;
    background: #fff;
    font-family: 'Raleway';
    border-radius: 15px
}
.first-section{
    display: flex;
    flex-direction: row;
    margin-bottom: 30px
}
.logo-text{
    padding: 20px 0 0 10px
}
.project{
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: #E9E9E9;

}
.project-items{
    margin-right: 50px;
    display: flex;
    padding: 15px;
}
.project-items:hover{
    background: #EEEEEE;
}
.project-icons{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background: #000;
}
.project-name{
    padding: 5px 10px 0 15px 
}
.third-section{
    margin-top: 20px
}
.add-project{
    display: flex;
    width: 180px;
    padding: 15px;
    border-radius: 25px;
    font-weight: 900;
    color: #fff;
    background-color: #876DE0;
    border: 1px solid #876DE0;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    letter-spacing: 1.5px
}
